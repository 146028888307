

@font-face {
  font-family: "Roboto-Thin";
  src: url("../font/Roboto-Thin.ttf");
}

@font-face {
    font-family: "Roboto-Light";
    src: url("../font/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../font/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Nunito-Italic";
  src: url("../font/Nunito-Italic.ttf");
}


@font-face {
  font-family: "Roboto-Regular";
  src: url("../font/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../font/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Nutito-Regular";
  src: url("../font/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nutito-Bold";
  src: url("../font/Nunito-Bold.ttf");
}

@font-face {
  font-family: "Nutito-Light";
  src: url("../font/Nunito-Light.ttf");
}

.strongtext{
  font-family:'Roboto-Medium'
}
.journalname{
  font-family:'Nunito-Italic'
}

/* mouse over link */
a:hover {
  color:#FAE058;
  text-decoration: none;
}

/* selected link */
a {
  color: #68CAFA;
}
* {
	-webkit-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}


.navigationBarLink{
  color:#FFFFFF;
  text-decoration: none;
  padding-right: 15px;
}

.navigationBarLink:hover{
  color:#68CAFA;
  text-decoration: none;
}


.navigationBarLink_Light{
  color:#404040;
  text-decoration: none;
  padding-right: 15px;
}

.navigationBarLink_Light:hover{
  color:#63BCE5;
  text-decoration: none;
}

.rowC{
  display:flex; 
  flex-direction:row;
  padding-top:2em}

.rowCenterButton{
    display:flex; 
    flex-direction:row;
    padding-top:1em;
    width:100%;
    background:red}
  


  /* Animation Styling  */

.one-second-animation {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.one-second-fadein {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}


.three-second-fadein {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

.five-second-fadein {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

.seven-second-fadein {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 7s;
  -webkit-animation: fadeIn ease 7s;
  -moz-animation: fadeIn ease 7s;
  -o-animation: fadeIn ease 7s;
  -ms-animation: fadeIn ease 7s;
}


.nine-second-fadein {
  font-size: 1em;
  text-align: left;
  color: #C6E1EA;
  font-family: 'Nutito-Regular';
  animation: fadeIn ease 9s;
  -webkit-animation: fadeIn ease 9s;
  -moz-animation: fadeIn ease 9s;
  -o-animation: fadeIn ease 9s;
  -ms-animation: fadeIn ease 9s;
}




/* Key Frames */

@keyframes fadeIn {
  0% {opacity:0;}
  40%{opacity:0}
  100% {opacity:1;}
}
  
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  40%{opacity:0}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  40%{opacity:0}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  40%{opacity:0}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  40%{opacity:0}
  100% {opacity:1;}
}